@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

body {
    width: auto;
    height: auto;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    color: black;
}

main {
    margin: 4px 0 0 0;
    min-height: 90%;
}

/* Header */
main .block-heading {
    padding-top: 40px;
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
}

main .block-heading img {
    width: 480px;
}

main .block-heading p {
    text-align: center;
    max-width: 60%;
    margin: 18px auto;
}

main .block-heading h1,
main .block-heading h2,
main .block-heading h3 {
    margin-bottom: 1.2rem;
}

/* Card Payment Section - Start */

.payment-form {
    padding-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px;
}

.payment-form.dark {
    background-color: #1d2431;
}

.payment-form .content {
    background-color: #fff;
}

.payment-form {
    background-color: #fff;
    padding: 0;
    max-width: 600px;
    margin: auto;
}

.payment-form a, .payment-form a:not([href]) {
    margin: 0;
    padding: 0;
    font-size: 13px;
    cursor: pointer;
}

.payment-form a:not([href]):hover {
    cursor: pointer;
}

/* Payment Result Section - Start */
.container__result {
    border: 1px solid #E5E5E5;
    border-top: none;
    border-radius: 16px;
    margin-bottom: 16px;
    padding-bottom: 16px;
}

.container__result .content {
    text-align: center;
}

.fadeOut {
    opacity: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn {
    opacity: 1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.container__payment {
    text-align: center;
}

.button-layout {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-message {
    font-weight: bold;
    text-decoration-line: underline;
    font-size: 13px;
}

.content-savemessage{
    color:#28a745;
    font-weight: bold;
    font-size: 13px;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-align: center;
    text-align: -webkit-center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 6px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
    border-style: solid;
    min-height: 48px;
    max-height: 48px;
    min-width: 200px;
    max-width: 200px;
    margin: 4px;
}

.btn-primary {
    color: #fff;
    background-color: #009EE3;
    border-color: #009EE3;
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn:disabled {
    /* Estilos para el botón deshabilitado */
    color: #b0b0b0; /* Cambia el color del texto */
    background-color: #d6d6d6; /* Cambia el color de fondo */
    border-color: #c0c0c0; /* Cambia el color del borde */
    cursor: not-allowed; /* Cambia el cursor a 'not-allowed' cuando está deshabilitado */
}

.icon {
    display: flex;
    margin-right: 4px;
    width: 20px;
    height: 20px;
    background-color: #fff;
}

.back-icon {
    -webkit-mask: url(../assets/back_icon.svg) no-repeat center;
    mask: url(../assets/back_icon.svg) no-repeat center;
}

.retry-icon {
    position: relative;
    top: 2px;
    -webkit-mask: url(../assets/retry_icon.svg) no-repeat center;
    mask: url(../assets/retry_icon.svg) no-repeat center;
}

.save-icon {
    position: relative;
    bottom: 1px;
    -webkit-mask: url(../assets/save_icon.svg) no-repeat center;
    mask: url(../assets/save_icon.svg) no-repeat center;
}

.check-icon {
    display: inline-block;
    position: relative;
    top: 4px;
    background-color: #06BC42FF;
    -webkit-mask: url(../assets/check_icon.svg) no-repeat center;
    mask: url(../assets/check_icon.svg) no-repeat center;
}

.cross-icon {
    display: inline-flex;
    position: relative;
    top: 4px;
    background-color: red;
    -webkit-mask: url(../assets/cross-icon.svg) no-repeat center;
    mask: url(../assets/cross-icon.svg) no-repeat center;
}

.saved-card {
    margin-left: 8px;
}

.saved-card-error {
    margin-left: 16px;
}

.icon.loading-spinner {
    border-radius: 50%;
    background-color: #28a745;
    border: 3px solid #19f519;
    border-top-color: #004d06;
    border-right-color: #004d06;
    animation: spin 1s linear infinite;
}

.error-layout {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.error-layout h2 {
    font-weight: 400;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.results-info {
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    max-width: calc(480px - (2 * 16px));
    margin: 0 auto 32px;
}

.results-info .results-layout {
    padding: 16px;
}

.results-info .results-layout p {
    margin: 0;
}

.results-info .transaction-details {
    display: flex;
    align-items: center;
}

.results-info .transaction-details .transaction-details-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    border: 1px solid #E5E5E5;
    border-radius: 100%;
}

.results-info .transaction-details .details-container {
    display: flex;
    flex-direction: column;
}

.results-info .transaction-details .details-container .title {
    margin: 0;
    padding-bottom: calc(16px / 4);
    font-size: 16px;
    color: #1A1A1A;
    text-align: left;
}

.results-info .transaction-details .details-container .subtitle {
    color: #737373;
    font-size: 14px;
    text-align: left;
}

.payment-status-layout {
    max-width: calc(480px - (2 * 16px));
    margin: 0 auto 32px;
    font-size: 14px;
    font-weight: 400;
}

/** MP Bricks classes */
.container__payment [class*="mp-checkout-bricks__payment-form"] > [class*="row"],
.container__result [class*="mp-checkout-bricks__payment-form"] > [class*="row"] {
    align-items: center;
    margin-bottom: 4px;
}

.container__result #statusScreenBrick_container section > [class*="status-body"] {
    margin-bottom: 0;
}

.container__result [class*="status-body"] section [class*="wrapper"] [class*="content"] p,
.container__result [class*="status-body"] [class*="details"] [class*="wrapper"] [class*="content"] p {
    text-align: left;
}

.container__result [class*="wrapper"]:not([class*="wrapper-"]) {
    margin: 8px;
}

/** MP Bricks classes */
.container__payment #paymentBrick_container [class*="mp-checkout-bricks__payment-form"] {
    padding-top: 0;
}

.container__payment [class*="mp-checkout-bricks__payment-form"] > [class*="mp-checkout-bricks__payment-options"] {
    border: none;
    margin-bottom: 12px;
}

.container__payment [class*="mp-checkout-bricks__payment-options"] > [class*="mp-checkout-bricks__selector"] {
    border: 1px solid #E5E5E5;
    border-radius: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
}

.container__payment [class*="mp-checkout-bricks__payment-options"] > [class*="mp-checkout-bricks__selector"][class*="active"] {
    border-color: #e02020;
    background-color: #fff;
    border-bottom: 1px solid #E5E5E5;
    border-radius: 16px 16px 0 0;
    margin-bottom: 0;
    box-shadow: none;
}

.container__payment [class*="mp-checkout-bricks__selector"][class*="active"] > [class*="mp-checkout-bricks__selector-text-container"] > [class*="mp-checkout-bricks__selector-text"] {
    color: #e02020;
}

.container__payment [class*="mp-checkout-bricks__selector"][class*="active"] + [class*="mp-checkout-bricks__payment-option"] {
    border: 1px solid #e02020;
    border-top: none;
    margin-bottom: 16px;
    border-radius: 0 0 16px 16px;
}

.container__payment [class*="mp-checkout-bricks__selector"][class*="active"] > [class*="mp-checkout-bricks__selector-icon"] {
    border-color: #e02020;
}

.container__payment [class*="mp-checkout-bricks__selector"] [class*="mp-checkout-bricks__selector-text-container"] {
    width: 80%;
}

.container__payment [class*="mp-checkout-bricks__selector"] [class*="mp-checkout-bricks__selector-text-container"] > [class*="mp-checkout-bricks__selector-text"] {
    font-size: 14px;
}

.container__payment [class*="mp-checkout-bricks__selector"] [class*="mp-checkout-bricks__selector-text-container"] > [class*="mp-checkout-bricks__selector-text"] span {
    display: none;
}

.container__payment [class*="mp-checkout-bricks__selector"] > [class*="mp-checkout-bricks__radio"] {
    display: none;
}

.container__payment [class*="mp-checkout-bricks__selector"] > [class*="mp-checkout-bricks__selector-icon"] {
    margin-left: 0;
}


/** Medium screens and above */
@media (min-width: 576px) {
    .payment-form .title {
        font-size: 1.2em;
    }

    .payment-form .payment-details {
        padding: 40px 40px 30px;
    }

    .payment-form .payment-details button {
        margin-top: 1em;
        margin-bottom: 15px;
    }
}

/** Mobile */
@media (max-width: 575px) {
    main .block-heading {
        font-size: 16px;
        margin-bottom: 0;
    }

    main .block-heading p {
        text-align: center;
        margin: 16px auto;
        max-width: 90%;
    }

    main .block-heading img {
        max-width: 320px;
    }

    .button-layout {
        display: inherit;
        text-align: center;
        text-align: -webkit-center;
        padding-left: 16px;
        padding-right: 16px;
    }

    .content-message {
        font-weight: bold;
        text-decoration-line: underline;
        font-size: 0.81em;
    }

    .content-savemessage{
        color:#28a745;
        font-weight: bold;
        font-size: 0.81em;
    }

    .btn {
        min-width: 100%;
        margin: 4px 0;
    }

    .save-card {
        margin-left: 0;
        margin-top: 16px;
    }

    .saved-card {
        display: block;
        margin-top: 12px;
        margin-left: 0;
    }

    .saved-card-error {
        display: block;
        margin-left: 0;
        margin-top: 12px;
    }

    .error-layout {
        width: 80%;
        font-size: 14px;
    }

    .results-info {
        margin: 0 16px 16px;
    }

}

.show {
    display: block
}

.hidden {
    display: none
}