
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);  /*ajustar la opacidad (por ejemplo, a 0.05) si el spinner no es lo suficientemente transparente */
    border-left-color: rgba(0, 158, 227, 1);/*rgba(9, 159, 255, 0.5);  cambiar el color y la opacidad para la apariencia deseada */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 480px) {
    .spinner {
      width: 30px;
      height: 30px;
    }
  }


